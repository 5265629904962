// JavaScript Document

// Scripts written by Jacob Bearce @ Weblinx, Inc.

import "lazysizes";
import "lazysizes/plugins/native-loading/ls.native-loading";

document.addEventListener("DOMContentLoaded", () => {
    window.dispatchEvent(new Event("resize"));
});
