import "bootstrap";
import "owl.carousel";

import jQuery from "jquery";

/* ===== Document Ready ======= */
jQuery(document).ready(function () {

    jQuery(".widget_wysija_cont").find(".wysija-input").val("");

    const $div = jQuery("<div>", {id: "submit-mail", "class": "submit-mail"});
    const $div2 = jQuery("<div>", {id: "submit-element", "class": "submit-element"});
    jQuery(".widget_wysija ").append($div);
    jQuery(".wysija-paragraph").appendTo("#submit-mail");
    jQuery(".widget_wysija ").find("#submit-mail").append($div2);
    jQuery(".wysija-submit").appendTo("#submit-element");

    /* ************** Touch *************** */
    if (!("ontouchstart" in document.documentElement)) {
        document.documentElement.className += " no-touch";
    } else {
        document.documentElement.className += " touch";
    }

    /* ************** Menu Setting ************* */

    if (jQuery(window).width() > 767) {
        jQuery(".touch .main-navigation  .menu li > a").on("click touchstart", function (event) {
            if (!jQuery(this).parent().hasClass("open") && jQuery(this).parent().has("ul").length > 0) {
                event.preventDefault();
                event.stopPropagation();
                jQuery(this).parent().addClass("open").siblings().removeClass("open");
            }
        });
    }

    jQuery(".main-navigation .menu li.menu-item-has-children > a + span.menu-arrow").on("click touchstart", function (event) {
        if (jQuery(this).parent().has("ul").length > 0) {
            event.preventDefault();
            event.stopPropagation();
            jQuery(this).parent().toggleClass("open").siblings().removeClass("open");
        }
    });

    jQuery(".nav-icon").on("click", function () {
        jQuery("body").toggleClass("open-menu");
    });

    jQuery(document).on("click", function () {
        jQuery("body").removeClass("open-menu");
    });

    jQuery(".nav-icon").on("click", function (e) {
        e.stopPropagation();
    });

    /* ************** banner-slider ************* */
    jQuery(".banner-slider .owl-carousel").owlCarousel({
        items: 1,
        autoplay: true,
        loop: (jQuery(this).find(".owl-carousel .item").length > 1) ? true : false,
        dots: true,
        responsive:{
            0:{
                autoHeight:true,
            },
            992:{
                autoHeight:false
            }
        }
    });

});
